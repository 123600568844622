<template>
<div>

  <div class="about">
    <h1>This is an about page</h1>
    <Message>Welcome to PrimeVue</Message>
<Menu :model="items" />
<router-view />
  </div>
    <div class="about">
    <h1>This is an about page</h1>
    <Message>Welcome to PrimeVue</Message>
<Menu :model="items" />
<router-view />
  </div>
    <div class="about">
    <h1>This is an about page</h1>
    <Message>Welcome to PrimeVue</Message>
<Menu :model="items" />
<router-view />
  </div>
    <div class="about">
    <h1>This is an about page</h1>
    <Message>Welcome to PrimeVue</Message>
<Menu :model="items" />
<router-view />
  </div>
    <div class="about">
    <h1>This is an about page</h1>
    <Message>Welcome to PrimeVue</Message>
<Menu :model="items" />
<router-view />
  </div>
    <div class="about">
    <h1>This is an about page</h1>
    <Message>Welcome to PrimeVue</Message>
<Menu :model="items" />
<router-view />
  </div>
  </div>
</template>
<script>
export default {
	data() {
		return {
			items: [
                {label: 'Home', icon: 'pi pi-fw pi-home', to: '/tabmenu'},
                {label: 'Calendar', icon: 'pi pi-fw pi-calendar', to: '/tabmenu/calendar'},
                {label: 'Edit', icon: 'pi pi-fw pi-pencil', to: '/tabmenu/edit'},
                {label: 'Documentation', icon: 'pi pi-fw pi-file', to: '/tabmenu/documentation'},
                {label: 'Settings', icon: 'pi pi-fw pi-cog', to: '/tabmenu/settings'}
            ]
		}
	}
}
</script>